import listingAdapter from '@/features/listing/requests/adapters/listing-adapter';
import { ListingTypeId } from '@/features/listing/types/listing-types';
import { type UserListingsDataResponse } from '@/features/listing/types/user-listings-response-types';
import { type UserListingsData } from '@/features/listing/types/user-listings-types';

export default function userListingsAdapter(userListingsResponse: UserListingsDataResponse): UserListingsData {
  // let isDataMissing = false;

  const userListings = userListingsResponse.listings.map((userListing) => {
    let isDataMissingListing = false;

    if (userListing.missing_data && userListing.missing_data.length > 0) {
      // isDataMissing = true;
      isDataMissingListing = true;
    }

    return {
      ...listingAdapter(userListing),
      isDataMissing: isDataMissingListing,
      missingDataFields: userListing.missing_data,
    };
  });

  const uniqueListings = [...new Map(userListings.map((item) => [item.id, item])).values()];
  // if one listing and listing type is haveapartment or haveshare
  const isAddressVerificationShow = uniqueListings.length === 1 && (uniqueListings[0].listingTypeId === ListingTypeId.haveapartment || uniqueListings[0].listingTypeId === ListingTypeId.haveshare);

  return {
    count: userListingsResponse.count,
    isAddressVerificationShow,
    isDataMissing: userListingsResponse.is_data_missing,
    listings: userListings,
  };
}
